import React, { useEffect, useState } from 'react';
import API from '../Network/API';
import toast from 'react-hot-toast';

const Help = () => {
    const [loading, setLoad] = useState(false);
    const [data, setData] = useState()
    const handleSubmit = (e) => {
        e.preventDefault();
    
        setLoad(true)
        const fd = new FormData();
        fd.append('subject', e.target.subject.value);
        fd.append('message', e.target.message.value);
        API.post("/v1/oauth/save_help_request", fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
                console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then((response) => {
            setLoad(false)
            toast.success('Submit successfully');
            fetchHelp();
            e.target.reset(); // Reset form after successful submission
        }).catch((err) => {
            setLoad(false)
            toast.error(err.response.data.message);
        });

    };

    const fetchHelp = () => {
        
        API.get(`/v1/oauth/get_help_request`)
        .then((response) => {
            setData(response.data.data)
        }).catch((error) => {
        });
    }

    useEffect(() => {

        fetchHelp();
    }, []);

    return (
        <>
        <div className="dashboard-title d-flex bg border-bottom align-items-center justify-content-between mb-4">
        <h1>Help</h1>
        <p>If you need any assistance, please contact the admin using the form below.</p>
            </div>
            <div style={{ padding: '20px' }}>
               
                <form onSubmit={handleSubmit}>
                    <div style={{ marginBottom: '10px' }}>
                        <label htmlFor="name">Subject:</label>
                        <input type="text" id="subject" name="subject" required className="form-control" />
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <label htmlFor="message">Message:</label>
                        <textarea id="message" name="message" required className="form-control"></textarea>
                    </div>
                    {loading ? (
                            <span
                                className="spinner-border spinner-border-sm ml-5"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        ) : (
                            <button type="submit" className="btn btn-sm btn-success">Submit </button>
                        )}
                </form>
            </div>
            <div className="col-12">
                <div className="table-responsive custom-table">
                    <table className="table table-nowrap mb-0">
                        <thead>
                            <tr>
                                <th>Token Number</th>
                                <th>Date</th>
                                <th>Subject</th>
                                <th>Message</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((item, index) => (
                                <tr key={index}>
                                    <td>{item?.id}</td>
                                    <td>{new Date(item?.created_at).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}</td>
                                    <td>{item?.subject}</td>
                                    <td>{item?.message.length > 50 ? item?.message.substring(0, 50) + '...' : item?.message}</td>
                                    <td>{item?.read == 0 ? 'Pending' : item?.reply ? 'Replied' : 'Read'}</td>
                                    <td>
                                        <a href='#' className="btn btn-sm btn-primary">View</a>
                                    </td>
                                </tr>
                            ))}

                            </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default Help;