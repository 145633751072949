import { Outlet } from "react-router-dom";
import ChatNav from "../../Props/ChatNav";
import API from "../../Network/API";
import { CHAT_LIST_FETCH_SUCCESS } from "../../Reducer/chatListSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export default function StudentChat(){
   const dispatch = useDispatch();
  useEffect(() => {
       
            API.get(`/v1/oauth/chatlist`).then((response) => {
              dispatch(CHAT_LIST_FETCH_SUCCESS(response.data.data))
            }).catch((error) => {
            });
  
        }, []);
        
    return(
        <>
        <div className="settings-widget card-info user-massage-area shadow-sm">
        <div className="settings-widget-body user-massage-body">
          <div className="row">
            <div className="col-md-6 col-lg-5 col-xl-5 mb-4 mb-md-0 col-3">
              <div className="pe-3 massage-user-list">
                <div className="input-group rounded mb-3 chat-search">
                  <input type="search" className="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                  <span className="input-group-text border-0" id="search-addon">
                    <i className="fa-light fa-search" />
                  </span>
                </div>
                <div className="user-chat-list" style={{position: 'relative', height: '400px'}}>
                  <ChatNav/>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-7 col-xl-7 col-9">
              <Outlet/>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}