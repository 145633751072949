import React, { useState } from 'react';
import SettingNav from './SettingNav';
import { USER_LOGGED_IN } from '../../Reducer/authSlice';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../Network/API';

const BankInfoEdit = () => {
    const { user, accessToken } = useSelector((state) => state.authReducer);
    const [bankName, setBankName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [loading, setLoad] = useState(false);
     const dispatch = useDispatch();
    const handleSubmit = (e) => {
        e.preventDefault();
        const fd = new FormData();
        fd.append('bank_name', e.target.bank_name.value);
        fd.append('account_number', e.target.account_number.value);
        fd.append('bank_additional', e.target.bank_additional.value);
        API.post("/v1/oauth/teacher/settings/bank_info", fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
                console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then((response) => {
            setLoad(false)
            toast.success('Bank update successfully');
            localStorage.setItem(
                'xller_auth',
                JSON.stringify({
                    user: response?.data?.data?.user,
                    accessToken: localStorage.getItem('xller_token'),
                })
            )

            const userInfo = JSON.parse(localStorage.getItem("xller_auth"));
            if (userInfo) {
                dispatch(USER_LOGGED_IN(userInfo));
            }

        }).catch((err) => {
            toast.error(err.response.data.message);
        });

    };

    return (
        <div className="settings-widget card-details shadow-sm">
            <div className="settings-menu p-0">
                <div className="profile-heading">
                    <h3>Settings</h3>
                    <p>You have full control to manage your own account settings</p>
                </div>
                <div className="settings-page-head">
                    <SettingNav active_menu={`bank`} />
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="checkout-form settings-wrap">
                        <div className="edit-profile-info">
                            <h5>Bank Info</h5>
                            <p>Edit your bank information</p>
                        </div>

                        <div className="form-group mb-3 row">

                            <label htmlFor="bankName" className="col-sm-3 col-form-label">Bank Name<em className='text-danger'> *</em></label>
                            <div className="col-sm-9">
                                <input
                                required
                                    type="text"
                                    className="form-control"
                                    id="bankName"
                                    
                                    name='bank_name'
                                    defaultValue={user?.teacher_info?.bank_name}
                                />
                            </div>
                        </div>
                        <div className="form-group mb-3 row">
                            <label htmlFor="accountNumber" className="col-sm-3 col-form-label">Account Number<em className='text-danger'> *</em></label>
                            <div className="col-sm-9">
                                <input
                                required
                                    type="text"
                                    className="form-control"
                                    id="accountNumber"
                                    name='account_number'
                                    defaultValue={user?.teacher_info?.account_number}
                                />
                            </div>
                        </div>
                        <div className="form-group mb-3 row">
                            <label htmlFor="routingNumber" className="col-sm-3 col-form-label">iban Number<em className='text-danger'> *</em></label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="routingNumber"
                                  required
                                    name='bank_additional'
                                   defaultValue={user?.teacher_info?.bank_additional}
                                />
                            </div>
                        </div>

                        <div className="mb-3 d-flex justify-content-end">
                            <button type="submit" className="input-hight-44 btn btn-login box-shadow-1 fw-bold">
                                Update {loading ? (
                                    <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>
                                ) : (
                                    <span></span>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BankInfoEdit;