import React from 'react';

const WithdrawRequestTr = ({ data }) => {
    return (
        <tr>
            <td>{data.id}</td>
            <td>{data.created_at}</td>
            <td>{data.payment_method}</td>
            <td>${data.amount}</td>
            <td>${data.fees}</td>
            <td>${data.amount-data.fees}</td>
            <td>{data.approved == 1 ? 'Approved' : 'Pending'}</td>
            <td>
                {data.payment_method == 'paypal' && data.bank_additional}
                <p className='mb-0 pb-0'>{data.payment_method == 'bank' && data.bank_name}</p>
                <p className='mb-0 pb-0'>{data.payment_method == 'bank' && data.account_number}</p>
                <p className='mb-0 pb-0'>{data.payment_method == 'bank' && data.bank_additional}</p>
                
            </td>
        </tr>
    );
};

export default WithdrawRequestTr;