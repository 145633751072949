import { useEffect, useState } from "react";
import API from "../Network/API";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import PostLoader from '../assets/loader.gif';
import { useSelector } from "react-redux";
import ScheduleSlot from "../components/ScheduleSlot";
import LessonModal from "../Modal/LessonModal";
import CourseLesson from "../Props/CourseLesson";
import CourseContent from "../Props/CourseContent";
export default function CourseDetail() {
  const location = useLocation();
  const isAppRoute = location.pathname.startsWith("/app");
  const { user, accessToken } = useSelector((state) => state.authReducer);
  const [show, setShow] = useState(false);
  const prams = useParams();
  const [data, setData] = useState();
  const [schedule, setSchedule] = useState();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate()
  const [row_data, setRowData] = useState(null);
  const [lesson_data, setLessonData] = useState(null);
  const handleShow = (row, lesson) => {
    setShow(!show)
    setRowData(row)
    setLessonData(lesson)
  };


  useEffect(() => {

    setLoading(true)
    API.get(`/v1/oauth/course_detail/${prams.slug}`).then((response) => {
      setLoading(false)
      setData(response.data.data.course)
    }).catch((error) => {
      setLoading(false)
    }).finally(
      window.scrollTo(0, 0)
    );
  }, [prams.slug]);

  const checkout = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    localStorage.setItem(
      'data_checkout',
      JSON.stringify({
        course_id: data?.id,
        course_slug: data?.slug,
        course_title: data?.title,
        course_type: data?.course_type,
        lesson_id: data?.trial_lessons?.id,
        price: data?.course_type == 'recorded' ? data?.course_price : data?.trial_lessons?.lesson_price,
        total_review: data?.ratings_count,
        avg_rating: data?.avg_rating,
        teacher_name: data?.teacher?.name,
        teacher_id: data?.teacher?.id,
        teacher_image: data?.teacher?.avatar,
        timezone: timezone,
      })
    )

    if (!accessToken) {
      if (isAppRoute) {
        navigate('/app/login')
      } else {
        navigate('/login')
      }
    } else {
      var price = data?.course_type == 'recorded' ? data?.course_price : data?.trial_lessons?.lesson_price
      if (price == 0 || price == undefined) {
        alert("Price not found");
        return;
      }
      if (isAppRoute) {
        navigate('/app/checkout')
      } else {
        navigate('/checkout')
      }


    }

  }


  const save_course_fav = (id) => {
    const confirmed = window.confirm("Are you sure you want to save this course to your favorites?");
    if (confirmed) {
      const fd = new FormData();
      fd.append('course_id', id)
      API.post(`/v1/oauth/student/save-favourite-courses`, fd).then((response) => {
        alert('course saved successfully')
      }).catch((error) => {
      });
    }

  }

  const un_save_course_fav = (id) => {
    const confirmed = window.confirm("Are you sure you want to remove this course to your favorites?");
    if (confirmed) {
      const fd = new FormData();
      fd.append('course_id', id)
      fd.append('type', 'remove')
      API.post(`/v1/oauth/student/save-favourite-courses`, fd).then((response) => {
        alert('course saved successfully')
      }).catch((error) => {
      });
    }

  }

  // handle mouse enter
  const handleMouseEnter = (e) => {
    const vid = e.target
    vid.muted = true
    vid.play()
  }
  // handle mouse leave
  const handleMouseLeave = (e) => {
    const vid = e.target
    vid.muted = false
    vid.currentTime = 0
    vid.pause()
  }

  return (
    <>
      {isLoading ?
        <div className='content' style={{ margin: 'auto', padding: 'auto', textAlign: 'center', height: '500px' }}>
          <img src={PostLoader} alt='Post loader' height={80} width={80} />
        </div>
        :
        <div className="courseDetails">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-12">
                <div className="mainInfoWrapper">
                  <div className="mainInfoWrapperAuthorInfo">
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <div className="AvatarWrapper position-relative">
                          <img src={data?.teacher?.avatar ? data.teacher.avatar : '/assets/img/user-default.png'} alt={data?.teacher?.name} />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <div className="Authorinfo">
                          <div className="step-upload-photos-name d-flex  align-items-center">
                            <h4 className="me-2 fs-24-600 m-0">{data?.teacher?.name}</h4>
                            {/* <img src="/assets/img/bd.svg" alt="BD" /> */}
                          </div>
                          <p className="m-0 fs-16-400">{data?.teacher?.teacher_info?.bio}</p>
                        </div>
                        <div className="step-upload-photos-text d-flex mb-2">
                          <i className="fa-light fa-graduation-cap me-2" />
                          <p className="m-0">{data?.teacher?.teacher_info?.education_level}</p>
                        </div>
                        <div className="step-upload-photos-text mb-2 d-flex">
                          <i className="fa-light fa-quote-left me-2" />
                          <p className="m-0">{data?.teacher?.teacher_info?.education_title}</p>
                        </div>
                        <div className="step-upload-photos-text d-flex">
                          <i className="fa-light fa-user me-2" />
                          <p className="m-0">{data?.lessons_count ?? 0} lessons</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mainInfoWrapperTab">
                    <div className="courseDetailsInfoWrapperTab">
                      <div className="dashboard__nav-wrap">
                        <ul className="nav nav-tabs" id="courseTab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="About" data-bs-toggle="tab" data-bs-target="#About-pane" type="button" role="tab" aria-controls="About-pane" aria-selected="true">
                              Details
                            </button>
                          </li>
                          {data != null && data?.course_type != "recorded" ?
                            <li className="nav-item" role="presentation">
                              <button className="nav-link" id="Schedule" data-bs-toggle="tab" data-bs-target="#Schedule-tab-pane" type="button" role="tab" aria-controls="Schedule-tab-pane" aria-selected="false" tabIndex={-1}>
                                Schedule
                              </button>
                            </li>
                            :
                            ''
                          }
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" id="Reviews" data-bs-toggle="tab" data-bs-target="#Reviews-tab-pane" type="button" role="tab" aria-controls="Reviews-tab-pane" aria-selected="false" tabIndex={-1}>
                              Reviews ({data?.ratings_count})
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content" id="courseTabContent">
                        <div className="tab-pane fade active show" id="About-pane" role="tabpanel" aria-labelledby="About" tabIndex={0}>
                          <div className="courseDetailsTabHeading">
                            <h2 className="fs-24-600 mb-0">About</h2>
                          </div>
                          <div className="courseDetailsTabDec">
                            <h4 className="pb-1">{data?.title}</h4>
                            <div className="fs-16-400 text-wrap" dangerouslySetInnerHTML={{ __html: data?.description }} />

                          </div>
                          <div className="courseDetailsTabHeading">
                            <h2 className="fs-24-600 mb-0">Requirements</h2>
                          </div>
                          <div className="courseDetailsTabDec">

                            <div className="fs-16-400" dangerouslySetInnerHTML={{ __html: data?.requirements }} />
                          </div>

                          {data?.course_type == 'recorded' ?

                            <>
                              <CourseContent data={data} />
                            </>
                            :
                            ''
                          }

                        </div>


                        {data != null && data?.course_type != "recorded" ?
                          <div className="tab-pane fade" id="Schedule-tab-pane" role="tabpanel" aria-labelledby="Schedule-tab" tabIndex={0}>
                            <div className="courseDetailsTabHeading">
                              <h2 className="fs-24-600 mb-0">Available Slots</h2>
                            </div>
                            <ScheduleSlot schedule={data?.teacher_schedules} />
                          </div>
                          :
                          ''
                        }

                        <div className="tab-pane fade" id="Reviews-tab-pane" role="tabpanel" aria-labelledby="Reviews-tab" tabIndex={0}>
                          <div className="courseDetailsTabHeading">
                            <h2 className="fs-24-600 mb-0">Reviews</h2>
                          </div>
                          <div className="courseDetailsTabReviews">
                            <div className="courseReviews">
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <div className="ratings-view">
                                    <h1 className="mb-2">{data?.avg_rating}</h1>
                                    <p className="mb-2 icon-20 text-warning">
                                      <i className={`fas fa-star ${data?.avg_rating >= 0 && data?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                                      <i className={`fas fa-star ${data?.avg_rating >= 2 && data?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                                      <i className={`fas fa-star ${data?.avg_rating >= 3 && data?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                                      <i className={`fas fa-star ${data?.avg_rating >= 4 && data?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                                      <i className={`fas fa-star ${data?.avg_rating >= 5 && data?.avg_rating <= 5 ? 'text-warning' : 'text-dark'}`} />

                                    </p>
                                    <p className="mb-0">{data?.ratings_count} reviews</p>
                                  </div>
                                </div>

                              </div>
                            </div>
                            <div className="courseRating">
                              <div className="courseRatingSorting">
                                {data?.ratings.length > 0 && data?.ratings.map((review, j) => (
                                  <div className="signalRating mb-3">
                                    <div className="card">
                                      <div className="card-body">
                                        <div className="d-flex">
                                          <div className="flex-shrink-0">
                                            <div className="userAvater">
                                              <img src={review?.student?.avatar ? review.student.avatar : "/assets/img/user-1.png"} alt={review?.student?.name} className="rounded" />
                                            </div>
                                          </div>
                                          <div className="flex-grow-1 ms-3">
                                            <div className="signalRatingHeader d-flex mb-1">
                                              <h5 className="m-0 fs-16-400 fw-bold">{review?.student?.name}</h5>
                                              <span className="ms-auto">{review?.created_at}</span>
                                            </div>
                                            <div className="rating mb-3">
                                              <span className="reviewsRatingColor">
                                                <i className={`fas fa-star ${review.rating >= 0 && review.rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                                                <i className={`fas fa-star ${review.rating >= 2 && review.rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                                                <i className={`fas fa-star ${review.rating >= 3 && review.rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                                                <i className={`fas fa-star ${review.rating >= 4 && review.rating <= 5 ? 'text-warning' : 'text-dark'}`} />
                                                <i className={`fas fa-star ${review.rating >= 5 && review.rating <= 5 ? 'text-warning' : 'text-dark'}`} />

                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <p className="m-0">{review?.comment}</p>
                                      </div>
                                    </div>
                                  </div>
                                ))}

                                {/* <a href="#" className="btn btn-link text-dark d-flex justify-content-end">See more reviews</a> */}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-12">
                <div className="courseDetails-SidePanelWrap">
                  <div className="courseDetails-SidePanel bg-white overflow-hidden w-100 p-0 shadow-sm">
                    <div className="courseDetails-SideMain ">
                      <div className="courseDetails-videoWrapper">

                        <div className="video-area">
                          {data?.cover_video ?
                            <>
                              <video key={0}
                                controls={true}
                                preload="metadata"
                                type="video/mp4"
                                height="200"
                                poster={`${data.cover_image}`}
                                oncontextmenu="return false;"
                                hideControls={['playbackrates']}
                                src={`${data?.cover_video}`}
                                className="w-100 video-hover-play"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                              />
                            </>
                            :
                            <div class="courseMainImg h-200" style={{ backgroundImage: `url(${data?.cover_image == null ? '/assets/img/no_image.jpg' : data.cover_image})` }}></div>
                          }
                        </div>
                      </div>
                      <div className="courseDetails-goal">
                        <div className="d-flex justify-content-evenly">
                          <div className="goals-count text-center">
                            <div className="courseDetails-goals-content ms-0">
                              <h4 className="fs-24-600 mb-0 fw-bold"><i className="fa-solid fa-star me-1" />{data?.ratings_count}</h4>
                              {/* <p className="mb-0">{data?.ratings_count} reviews</p> */}
                            </div>
                          </div>
                          <div className="goals-count text-center">
                            <div className="courseDetails-goals-content ms-0">
                              <h4 className="fs-24-600 mb-0 fw-bold"><i className="fa-solid fa-dollar me-1" />{data?.course_type == 'recorded' ? data?.course_price : data?.trial_lessons?.lesson_price}</h4>
                              {/* <p className="mb-0">{data?.course_type == 'recorded' ? `${data?.total_duration_in_minutes ?? 0}-min lesson` : ''}</p> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="courseDetails-btnWrapper">

                        {data?.course_type === 'recorded' ? (
                          user?.enrollments?.some(enroll => enroll.course_id === data?.id) ? (
                            <></>
                          ) : (

                            <button
                              onClick={() => checkout()}
                              type="button"
                              className={`input-hight-44 btn btn-login box-shadow-1 fw-bold mb-2 w-100 ${data?.gender_id === 3 ? 'd-block' : (data?.gender_id === user?.gender_id ? 'd-block' : 'd-none')}`}
                            >
                              <i className="fa-light fa-cart-shopping me-2" />
                              Book Course
                            </button>
                          )
                        ) : (


                          <button
                            onClick={() => handleShow(data, data?.trial_lessons)}
                            type="button"
                            className={`input-hight-44 btn btn-login box-shadow-1 fw-bold mb-2 w-100 ${data?.gender_id === 3 ? 'd-block' : (data?.gender_id === user?.gender_id ? 'd-block' : 'd-none')}`}
                          >
                            <i className="fa-light fa-cart-shopping me-2" />
                            Book Lesson
                          </button>

                        )}

                        {data?.teacher?.id != user?.id ?
                          <>
                            <Link to={`/home/chat/conversation/${data?.teacher?.id}`} className="input-hight-44 btn btn-dark box-shadow-1 fw-bold mb-2 w-100">
                              <i className="fa-light fa-message-lines me-2" />
                              Send message
                            </Link>
                            {user?.fav_course?.some((course) => course?.course_id === data?.id) ?
                              <button onClick={() => un_save_course_fav(data?.id)} type="button" className="input-hight-44 btn btn-dark box-shadow-1 fw-bold mb-2 w-100">
                                <svg
                                  className="me-2"
                                  style={{ fill: 'red', width: '24px', height: '24px' }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                                </svg>
                                Remove from my list
                              </button>
                              :
                              <button onClick={() => save_course_fav(data?.id)} type="button" className="input-hight-44 btn btn-dark box-shadow-1 fw-bold mb-2 w-100">
                                <i className="fa-light fa-heart me-2 " />
                                Save to my list
                              </button>
                            }


                          </>
                          : ''}


                      </div>
                      <div className="courseDetails-contentWrapper">

                        <div className="courseDetails-content d-flex mb-2">
                          <i className="fa-light fa-chart-line me-2" />
                          <p className="m-0">{data?.lessons_count} lessons/lectures </p>
                        </div>

                        <div className="courseDetails-content d-flex mb-2">
                          <i className="fa-light fa-star me-2" />
                          <p className="m-0">Super popular: : {data?.enrollments_count} students contacted this course</p>
                        </div>
                        {/* <div className="courseDetails-content d-flex mb-2">
                          <i className="fa-light fa-clock me-2" />
                          <p className="m-0">Usually responds in 1 hour</p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      <LessonModal show={show} onHide={handleShow} lesson={lesson_data} course={row_data} />
    </>
  )

}