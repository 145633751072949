import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import API from "../../Network/API";
import PostLoader from '../../assets/loader.gif';
import CourseImage from "../../CustomHooks/CourseImage";

export default function EnrollmentCourseDetails() {
    const dispatch = useDispatch();
    const [data, setData] = useState()
    const prams = useParams();
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        API.get(`/v1/oauth/teacher/order-details/${prams?.id}`)
            .then((response) => {
                setData(response.data.data)
                setLoading(false)
            }).catch((error) => {
                setLoading(false)
            });

    }, [prams?.id]);

    return (
        <>
         {isLoading ?
        <div className='content' style={{ margin: 'auto', padding: 'auto', textAlign: 'center', height: '500px' }}>
          <img src={PostLoader} alt='Post loader' height={80} width={80} />
        </div>
        :
          <>
            <div className="dashboard-title">
                <h4><Link to={`/dashboard/all-enrollment-courses`}><i class="fa-duotone fa-solid fa-arrow-left"></i></Link>Orders Details</h4>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <table className="table">
                        <tr>
                            <td colSpan={2}>
                                {/* <img src={data?.course?.course_image} height={100} width={100} /> */}
                                <CourseImage imageUrl={data?.course?.cover_image} title={data?.course?.title}/>
                   
                            </td>
                        </tr>
                        <tr>
                            <th width={'20%'}>Course Name:</th>
                            <td>{data?.course?.course_title}</td>
                        </tr>

                        <tr>
                            <th>Course Type:</th>
                            <td>{data?.course?.course_type == 'recorded' ? 'Recorded' : 'Live'}</td>
                        </tr>

                        <tr>
                            <th>Order Price:</th>
                            <td>${data?.course?.payment_info?.amount}</td>
                        </tr>
                    </table>
                </div>
                <div className="col-md-6">
                    <table className="table">
                        <tr>
                            <td colSpan={2}><img src={data?.course?.student_image} height={100} width={100} /></td>
                        </tr>
                        <tr>
                            <th width={'30%'}>Student Name:</th>
                            <td>{data?.course?.student_name}</td>
                        </tr>
                        <tr>
                            <th>Student Email:</th>
                            <td>{data?.course?.student_email}</td>
                        </tr>
                        <tr>
                            <th>Contact:</th>
                            <td><Link to={`/dashboard/chat/conversation/${data?.course?.student_id}`} className="btn btn-sm btn-success">Send Message</Link></td>
                        </tr>
                    </table>
                </div>
                <div className="col-md-12">
                    <h4>Earning Details</h4>
                </div>
                <div className="col-md-12">
                    <div className="table-responsive custom-table">
                        <table className="table table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Order Date</th>
                                    <th>Title</th>
                                    <th>Amount</th>
                                    <th>Fee</th>
                                    <th>Clearance</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{data?.course?.created_at}</td>
                                    <td>{data?.course?.lesson_name}</td>
                                    <td>${data?.course?.payment_info?.amount}</td>
                                    <td>{data?.earning_info?.fees!=null?`$${data?.earning_info?.fees}`:'-'}</td>
                                    <td>{data?.earning_info?.approved!=null?`$${data?.earning_info?.approved=='1'?'Approved':'Pending'}`:'Pending'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
          </>
}
        </>
    )
}