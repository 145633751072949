import React from 'react';

const CourseImage = ({ imageUrl, title }) => {
  const defaultImage = '/assets/img/no_image.jpg'; // Replace with your default image path

  return (
    <img
      src={imageUrl}
      alt={title || 'Default Title'}
      onError={(e) => {
        e.target.onerror = null; // Prevent infinite loop if default image also fails
        e.target.src = defaultImage;
      }}
    />
  );
};

export default CourseImage;