import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

export default function ChatNav() {
    const select_chatlist = useSelector((state) => state.chatListReducer);
    const prams = useParams();
    return (
        <>
            <ul className="list-unstyled mb-0 me-3">

                {select_chatlist?.data?.length > 0 && select_chatlist?.data?.map((user, i) => (
                    <li className={`p-2 border-bottom ${prams?.id == user?.to_id ? 'active' : ''}`} key={i}>
                        <Link to={`/home/chat/conversation/${user.to_id}`} className="d-flex justify-content-between">
                            <div className="d-flex flex-row">
                                <div>
                                    <img style={{borderRadius:'50%'}} src={user.image} alt={user.name} className="d-flex align-self-center me-2 chat-list-user-avatar" />
                                    <span className="badge bg-success badge-dot" />
                                </div>
                                <div className="pt-1 chat-user-info">
                                    <p className="fw-bold mb-0 chat-list-user-name">{user.name}</p>
                                    <p className="small text-muted mb-0 chat-list-user-massage">{user.message}</p>
                                </div>
                            </div>
                            {user.unread_count > 0 ?
                                <div className="pt-1 chat-user-seenTime">
                                    <p className="small text-muted mb-1">Just now</p>
                                    <span className="badge bg-danger rounded-pill float-end">{user.unread_count}</span>
                                </div> : ''
                            }
                        </Link>
                    </li>
                ))}
            </ul>
        </>
    )
}