import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import API from "../../../Network/API";
import { DELETE_MY_COURSE_ROUTE, MY_COURSE_FETCH_START, MY_COURSE_FETCH_SUCCESS, MY_COURSE_TOTAL_PAGE } from "../../../Reducer/myCourseSlice";
import PostLoader from '../../../assets/loader.gif';

export default function TutorCourse() {
  const select_course = useSelector((state) => state.myCourseReducer);
  const [isLoading, setLoading] = useState(false);
  const [loading, setLoad] = useState(false);
  const [course_id, setCourseId] = useState(0);
  const dispatch = useDispatch();
   const location = useLocation();
    const isAppRoute = location.pathname.startsWith("/app");
  
  useEffect(() => {
    setLoad(true)
    dispatch(MY_COURSE_FETCH_START())
    API.get(`/v1/oauth/teacher/mycourses`).then((response) => {
      dispatch(MY_COURSE_FETCH_SUCCESS(response.data.data))
      dispatch(MY_COURSE_TOTAL_PAGE(response.data.data.total_page))
      setLoad(false)
    }).catch((error) => {
      setLoad(false)
    });

  }, []);

  const delete_course = (id) => {
    setCourseId(id)

    if (window.confirm("Are you sure want to delete this?")) {
      setLoading(true)
      API.delete('/v1/oauth/teacher/course/' + id + '').then((response) => {
        setLoading(false)
        dispatch(DELETE_MY_COURSE_ROUTE(id))
      }).catch((error) => {
        setLoading(false)
      });
    }

  }
  return (
    <>

      {loading ?
        <div className='content' style={{ margin: 'auto', padding: 'auto', textAlign: 'center', height: '500px' }}>
          <img src={PostLoader} alt='Post loader' height={80} width={80} />
        </div>
        :
        <>
          <div className="dashboard-title d-flex justify-content-between align-items-center">
            <h4>All Courses</h4>
            <Link to={`/dashboard/my-course/create`} className="btn btn-dark"><i className="fa-light fa-plus-circle me-1" />Course Create</Link>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive custom-table">
                <table className="table table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th className="text-start">Image</th>
                      <th className="text-start">Course Title</th>
                      <th className="text-center">Enrolled</th>
                      <th className="text-center">Add Lesson</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Type</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!select_course?.loading && select_course?.courses?.length > 0 && select_course?.courses?.map((course, j) => (
                      <tr>
                        <td class="align-middle">
                          <img
                            height={70} width={70}
                            src={course?.cover_image || '/assets/img/no_image.jpg'}
                            alt={course?.title || 'Default Title'}
                            onError={(e) => {
                              e.target.onerror = null; // Prevent infinite loop if default image also fails
                              e.target.src = '/assets/img/no_image.jpg';
                            }}
                          />

                        </td>
                        <td class="align-middle">

                          <Link to={isAppRoute ? `/app/course/${course?.slug}` : `/course/${course?.slug}`} className="course-table-img main-course-img">

                            {course.title}
                          </Link>

                        </td >
                        <td className="text-center align-middle">{course.total_enrollment}</td>
                        <td className="text-center align-middle">
                          <Link to={`/dashboard/my-course/sections/${course.id}`} className="addLessonBtn icon-20">
                            <i className="fa-light fa-plus-circle" />
                          </Link>
                        </td>
                        <td className="text-center align-middle">{course.active == 1 ? 'Published' : 'Unpublished'}</td>
                        <td className="text-center align-middle">{course.course_type}</td>
                        <td className="text-center align-middle">
                          <Link to={`/dashboard/my-course/edit/${course?.id}`} title="Edit" className="table-action-btn"><i className="fa-light fa-pen-to-square" /></Link>
                          {course_id == course?.id && isLoading ? (
                            <span
                              className="spinner-border spinner-border-sm ml-5"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <a onClick={() => delete_course(course?.id)} href="#" title="Delete" className="table-action-btn"><i className="fa-light fa-trash-alt" /></a>
                          )}
                          <Link to={`/dashboard/my-course/view/${course?.id}`} title="view" className="table-action-btn ms-1"><i className="fa-light fa-eye" /></Link>

                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}