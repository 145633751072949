import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import PostLoader from '../../../assets/loader.gif';
import API from "../../../Network/API";
import CourseImage from "../../../CustomHooks/CourseImage";
import CourseContent from "../../../Props/CourseContent";
import CourseLesson from "../../../Props/CourseLesson";
import CourseTableRow from "../../../Props/CourseTableRow";

export default function CourseView() {
    const dispatch = useDispatch();
    const [data, setData] = useState()
    const prams = useParams();
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        API.get(`/v1/oauth/teacher/course/view/${prams?.id}`)
            .then((response) => {
                setData(response.data.data)
                setLoading(false)
            }).catch((error) => {
                setLoading(false)
            });

    }, [prams?.id]);


    return (
        <>
            {isLoading ?
                <div className='content' style={{ margin: 'auto', padding: 'auto', textAlign: 'center', height: '500px' }}>
                    <img src={PostLoader} alt='Post loader' height={80} width={80} />
                </div>
                :
                <>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mainInfoWrapperAuthorInfo">
                                <div className="d-flex">
                                    <div className="flex-shrink-0">
                                        <div className="AvatarWrapper position-relative">

                                            <img
                                                height={70} width={70}
                                                src={data?.course?.cover_image || '/assets/img/no_image.jpg'}
                                                alt={data?.course?.title || 'Default Title'}
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevent infinite loop if default image also fails
                                                    e.target.src = '/assets/img/no_image.jpg';
                                                }} />
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <div className="Authorinfo">
                                            <div className="step-upload-photos-name d-flex  align-items-center">
                                                <h4 className="me-2 fs-24-600 m-0">{data?.course?.title}</h4>
                                            </div>

                                        </div>
                                        <div className="step-upload-photos-text d-flex mb-2">
                                            <i className="fa-light fa-list me-2" />
                                            <p className="m-0">{data?.course?.category}</p>
                                        </div>
                                        <div className="step-upload-photos-text mb-2 d-flex">
                                            <i className="fa-light fa-quote-left me-2" />
                                            <p className="m-0">{data?.course?.lessons_count} Lessons</p>
                                        </div>
                                        <div className="step-upload-photos-text d-flex mb-2">
                                            <i className="fa-light fa-user me-2" />
                                            <p className="m-0">{data?.course?.enrollments_count} Enrollments</p>
                                        </div>
                                        <div className="step-upload-photos-text d-flex mb-2">
                                            <i className="fas fa-star text-dark me-2" />
                                            <p className="m-0">{data?.course?.avg_rating} Avg. Review</p>
                                        </div>
                                        <div className="step-upload-photos-text d-flex">
                                            <i className="fas fa-edit text-dark me-2" />
                                            <Link to={`/dashboard/my-course/edit/${data?.course?.id}`} title="Edit" className="fn-12">Edit Course</Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mainInfoWrapperTab">
                                <div className="courseDetailsInfoWrapperTab">
                                    <div className="dashboard__nav-wrap">
                                        <ul className="nav nav-tabs" id="courseTab" role="tablist">
                                            <li className="nav-item" role="presentation"><button className="nav-link active" id="About" data-bs-toggle="tab" data-bs-target="#About-pane" type="button" role="tab" aria-controls="About-pane" aria-selected="true">Lessons</button></li>
                                            <li className="nav-item" role="presentation"><button className="nav-link" id="Reviews" data-bs-toggle="tab" data-bs-target="#Reviews-tab-pane" type="button" role="tab" aria-controls="Reviews-tab-pane" aria-selected="false" tabIndex={-1}>Enrollments ({data?.course?.enrollments_count})</button></li>
                                        </ul>
                                    </div>
                                    <div className="tab-content" id="courseTabContent">
                                        <div className="tab-pane fade active show" id="About-pane" role="tabpanel" aria-labelledby="About" tabIndex={0}>
                                            <div className="courseDetailsTabHeading">
                                                {data?.course?.course_type == 'recorded' ?

                                                    <>
                                                        <CourseContent data={data?.course} />
                                                    </>
                                                    :
                                                    <>
                                                        <CourseLesson trial_lesson={data?.course?.trial_lessons} course={data?.course} />
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Reviews-tab-pane" role="tabpanel" aria-labelledby="Reviews-tab" tabIndex={0}>
                                            <div className="table-responsive custom-table">
                                                <table className="table table-nowrap mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Type</th>
                                                            <th>Student</th>
                                                            <th>Title</th>
                                                            <th>Lesson Name</th>
                                                            <th>Start Time</th>
                                                            <th>Remaining</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                     

                                                            <>
                                                                {data?.enrollments?.length > 0 && data?.enrollments?.map((enrollment, j) => (
                                                                    <CourseTableRow data={enrollment} key={j} />
                                                                ))}
                                                            </>
                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </>
            }
        </>
    )
}