import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../Network/API";
import { useDispatch, useSelector } from "react-redux";
import { ADD_MESSAGE_SUCCESS, DELETE_TEMP_MESSAGE, INBOX_FETCH_SUCCESS } from "../Reducer/userInboxSlice";
import ContentLoader from "react-content-loader";

export default function ChatBody() {
  const [demo_data, setData] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }]);
  const { user, accessToken } = useSelector((state) => state.authReducer);
  const data = useSelector((state) => state.userInboxReducer);
  const prams = useParams();
  const [isLoading, setLoading] = useState(false);
  const [isLoad, setLoad] = useState(false);
  const messagesEndRef = useRef(null);
  const [to_user, setUser] = useState();
  const [text, setText] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true)
    API.get(`/v1/oauth/get-conversation/${prams.id}`).then((response) => {
      setLoading(false)
      // setData(response.data.data.message)
      dispatch(INBOX_FETCH_SUCCESS(response.data.data))
      setUser(response.data.data.user)
    }).catch((error) => {
      setLoading(false)
    }).finally(
      window.scrollTo(0, 0)
    );
  }, [prams.id]);


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
}

const handleSubmit = (event) => {
  event.preventDefault();
    
    if (text == '' || text==undefined) {
      alert("Message is empty");
      return false;
    }

    setLoad(true)
    const fd = new FormData();
    fd.append('to_id', to_user?.id);
    fd.append('message', text);
    const payload = {
      temp_id: to_user?.id,
      from_id: user.id,
      to_id: to_user?.id,
      seen_at: null,
      message: text,
      sender: { name: user.name, avatar: user.avatar },
      created_at: "1s ago"
    }
    dispatch(ADD_MESSAGE_SUCCESS(payload))

    API.post("/v1/oauth/send_message", fd, {}).then((response) => {
      setLoad(false)
      dispatch(DELETE_TEMP_MESSAGE(to_user?.id))
      dispatch(ADD_MESSAGE_SUCCESS(response.data.data.message))
    })
      .catch((err) => {
        setLoad(false)
      });

      setText(null);
      event.target.reset();
      
      scrollToBottom();

  }

  return (
    <>
      <div className="chat-about border-bottom pb-2 mb-2">
        <div className="d-flex">
          <div className="chat-about-left me-2">
            <img src={to_user?.avatar} alt="avatar" className="d-flex align-self-center me-2 chat-list-user-avatar" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
          </div>
          <div className="chat-about-right">
            <h6 className="mb-0">{to_user?.name}</h6>
            <small className="d-none">{to_user?.last_seen}</small>
          </div>
        </div>
      </div>
      <div className="pt-3 pe-3 user-chat-area" style={{ position: 'relative', height: '400px' }}>

      {isLoading && demo_data?.map((post, index) => (
                                    <>
                                        <div className="d-flex flex-row justify-content-start" >
                                            <ContentLoader
                                                speed={2}
                                                width={400}
                                                height={80}
                                                viewBox="0 0 400 80"
                                                backgroundColor="#eeecec"
                                                foregroundColor="#eae6e6"
                                              
                                            >
                                                <circle cx="37" cy="47" r="17" />
                                                <rect x="65" y="36" rx="0" ry="0" width="239" height="8" />
                                                <rect x="66" y="50" rx="0" ry="0" width="198" height="7" />
                                            </ContentLoader>
                                        </div>

                                        <div className="d-flex flex-row justify-content-end" >
                                            <ContentLoader
                                                speed={2}
                                                width={400}
                                                height={80}
                                                viewBox="0 0 400 80"
                                                backgroundColor="#eeecec"
                                                foregroundColor="#eae6e6"
                                               
                                            >
                                                <circle cx="37" cy="47" r="17" />
                                                <rect x="65" y="36" rx="0" ry="0" width="239" height="8" />
                                                <rect x="66" y="50" rx="0" ry="0" width="198" height="7" />
                                            </ContentLoader>
                                        </div>
                                    </>

                                ))}

        {!isLoading && data?.messsage?.length > 0 && data?.messsage?.map((message, i) => (
          <>
            {message?.to_id != to_user?.id ?
              <div className="d-flex flex-row justify-content-start" key={i}>
                <img src={to_user?.avatar} alt={message?.receiver?.name} style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                <div>

                  <p className="small p-2 ms-3 mb-1 rounded-3 bg-body-tertiary">{message?.message}</p>
                  <p className="small ms-3 mb-3 rounded-3 text-muted float-end">{message?.time} | {message?.date}</p>
                </div>
              </div>
              :
              <div className="d-flex flex-row justify-content-end">
                <div>
                  <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">{message?.message}</p>
                  <p className="small me-3 mb-3 rounded-3 text-muted">{message?.time} | {message?.date}</p>
                </div>
                <img src={message?.sender?.avatar} alt={message?.sender?.name} style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
              </div>
            }
              <div className="messageEndArea" ref={messagesEndRef} />
          </>
        ))}
      </div>
      <form method="post" onSubmit={handleSubmit} >
      <div className="text-muted d-flex justify-content-start align-items-center pe-3 pt-3 mt-2">
        <img src={user?.avatar} alt="avatar 3" style={{ width: '40px', height: '100%', borderRadius: '50%' }} />
        <textarea   onChange={(e) => setText(e.target.value)} className="form-control form-control-sm ms-2" id="exampleFormControlInput2" placeholder="Type message" />
        <button type="submit" className="ms-3" style={{backgroundColor:'white',border:'none'}}><i className="fas fa-paper-plane" /></button>
      </div>
      </form>
    </>
  )
}