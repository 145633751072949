import { Link } from "react-router-dom";

export default function SettingNav(props){
    return (
        <>
        <ul className="settings-pg-links list-unstyled">
                        <li><Link to={`/dashboard/settings`} className={`${props.active_menu=='profile'?'active':''}`}>
                            <i className="fa-light fa-edit" />Edit Profile</Link>
                        </li>
                        <li><Link to={`/dashboard/settings/languages`} className={`${props.active_menu=='languages'?'active':''}`}><i class="fa-solid fa-globe" />Languages</Link></li>
                        <li><Link to={`/dashboard/settings/schedules`} className={`${props.active_menu=='schedules'?'active':''}`}><i class="fa-regular fa-calendar-days" />Schedules</Link></li>
                        <li><Link to={`/dashboard/settings/change-password`} className={`${props.active_menu=='password'?'active':''}`}><i className="fa-light fa-lock" />Change Password</Link></li>
                        <li><Link to={`/dashboard/settings/change-bank-info`} className={`${props.active_menu=='bank'?'active':''}`}><i className="fa-light fa-bank" />Bank Account</Link></li>
                        <li><a href="#"><i className="fa-light fa-bell" />Notifications</a></li>
                    </ul>
        </>
    )
}