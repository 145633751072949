import { Link, useLocation } from "react-router-dom";
import moment from 'moment-timezone'; // For timezone handling
import { useEffect, useState } from "react";

export default function CourseTableRow({ data }) {
  const [timeRemaining, setTimeRemaining] = useState('');
  const location = useLocation();
  const isAppRoute = location.pathname.startsWith("/app");

  useEffect(() => {
    const courseStartTimeUtc = moment.tz(data?.start_time, 'UTC');
    const interval = setInterval(() => {
      const now = moment(); // Current local time
      const diff = moment.duration(courseStartTimeUtc.diff(now)); // Difference between now and course start time

      if (diff.asSeconds() <= 0) {
        clearInterval(interval);
        setTimeRemaining('00:00:00');
      } else {
        const hours = String(Math.floor(diff.asHours())).padStart(2, '0');
        const minutes = String(diff.minutes()).padStart(2, '0');
        const seconds = String(diff.seconds()).padStart(2, '0');
        setTimeRemaining(`${hours}:${minutes}:${seconds}`);
      }
    }, 1000);

    return () => clearInterval(interval); // Clean up on component unmount
  }, []);
  return (
    <>
      <tr className="align-middle">
        <td>{data?.course?.course_type == 'recorded' ? 'Recorded' : 'Live'}</td>
        <td>{data?.student?.name}</td>
        <td>
          <div className="table-course-detail">
            <Link to={isAppRoute?`/app/course/${data?.course?.slug}`:`/course/${data?.course?.slug}`} className="course-table-img text-info">
              {/* <span><img src={data?.course?.cover_image} alt={data?.course?.title} /></span> */}
              {data?.course?.title}
            </Link>
          </div>
        </td>
        <td>{data?.lesson?.title}</td>
        <td>{data?.teacher_local_start_time}</td>
        <td className="fw-bold">

          {data?.course?.course_type == 'recorded' ? '' : timeRemaining}

        </td>
       
        <td>
          {data?.course?.course_type == 'recorded' ?
            <>
            <Link to={isAppRoute?`/app/dashboard/enrollment-details/${data?.id}`:`/dashboard/enrollment-details/${data?.id}`} className="btn btn-sm btn-primary ms-1">Details</Link>
            </>
            :
           <>
            <Link to={`/room/${data?.course?.slug}/${data?.room_no}`} className="btn btn-sm btn-success">Start</Link>
            <Link to={isAppRoute?`/app/dashboard/enrollment-details/${data?.id}`:`/dashboard/enrollment-details/${data?.id}`} className="btn btn-sm btn-primary ms-1">Details</Link>

           </>
          }
        </td>
      </tr>
    </>
  )
}