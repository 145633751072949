import { useEffect, useState } from "react";
import API from "../../../Network/API";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { USER_LOGGED_IN } from "../../../Reducer/authSlice";
import WithdrawRequestTr from "./WithdrawRequestTr";

export default function WithdrawIndex() {
    const { user, accessToken } = useSelector((state) => state.authReducer);
    const dispatch = useDispatch();
    const [data, setData] = useState()
    const [type, setType] = useState()
    const [show_bank, setShowBank] = useState(false)
    const [show_paypal_email, setShowPaypalEmail] = useState(true)
    const [loading, setLoad] = useState(false);


    const handleSubmit = (e) => {
        e.preventDefault();
        if(e.target.amount.value > user?.balance){
            toast.error('Insufficient balance');
            return;
        }
        setLoad(true)
        const fd = new FormData();
        fd.append('amount', e.target.amount.value);
        fd.append('type', e.target.type.value);
        fd.append('paypal_email', e.target.paypal_email.value);
        fd.append('bank_name', e.target.bank_name.value);
        fd.append('account_number', e.target.account_number.value);
        fd.append('bank_additional', e.target.bank_additional.value);
        API.post("/v1/oauth/teacher/withdraw_request", fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
                console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then((response) => {
            setLoad(false)
            toast.success('Withdraw request successfully');
            localStorage.setItem(
                            'xller_auth',
                            JSON.stringify({
                                user: response?.data?.data?.user,
                                accessToken: localStorage.getItem('xller_token'),
                            })
                        )
            
                        const userInfo = JSON.parse(localStorage.getItem("xller_auth"));
                        if (userInfo) {
                            dispatch(USER_LOGGED_IN(userInfo));
                        }

        }).catch((err) => {
            setLoad(false)
            toast.error(err.response.data.message);
        });

    };

    useEffect(() => {

        API.get(`/v1/oauth/teacher/withdraw_history`)
        .then((response) => {
            setData(response.data.data)
        }).catch((error) => {
        });

    }, []);

    const withdraw_method_check = (method) => {
        if (method === 'bank') {
            setShowBank(true)
            setShowPaypalEmail(false)
        } else {
            setShowBank(false)
            setShowPaypalEmail(true)
        }
    }

    return (
        <>
            <div className="dashboard-title d-flex bg border-bottom align-items-center justify-content-between mb-4">
                <h4>Withdraw Histories</h4>
            </div>
            <div className="row">
                <div className="col-md-8 offset-md-2 mb-4">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-2">
                            <label htmlFor="amount" className="form-label">Withdrawal Method:</label>
                            <select name="type" className="form-select form-select-sm" onChange={(e) => withdraw_method_check(e.target.value)}>
                                <option value={'paypal'}>Paypal</option>
                                <option value="bank">Bank</option>
                            </select>
                        </div>

                        <div className={`mb-2 ${show_bank ? 'd-none' : ''}`}>
                            <label htmlFor="amount" className="form-label">Paypal Email<em className="text-danger">*</em></label>
                            <input name="paypal_email"
                                type="email"
                                className="form-control"
                                autoSave="off"
                            />
                        </div>

                        <div className={`mb-2 ${show_paypal_email ? 'd-none' : ''}`}>
                            <label htmlFor="amount" className="form-label">Bank Name<em className="text-danger">*</em></label>
                            <input name="bank_name"
                                type="text"
                                className="form-control"
                                value={`${user?.teacher_info?.bank_name == null ? '' : user?.teacher_info?.bank_name}`}
                            />
                        </div>
                        <div className={`mb-2 ${show_paypal_email ? 'd-none' : ''}`}>
                            <label htmlFor="amount" className="form-label">Account Name<em className="text-danger">*</em></label>
                            <input
                                disabled
                                type="text"
                                className="form-control"
                                value={`${user?.name}`}
                            />
                        </div>
                        <div className={`mb-2 ${show_paypal_email ? 'd-none' : ''}`}>
                            <label htmlFor="amount" className="form-label">Account Number<em className="text-danger">*</em></label>
                            <input name="account_number"
                                type="text"
                                className="form-control"
                                value={`${user?.teacher_info?.account_number == null ? '' : user?.teacher_info?.account_number}`}
                            />
                        </div>
                        <div className={`mb-2 ${show_paypal_email ? 'd-none' : ''}`}>
                            <label htmlFor="amount" className="form-label">iban Number<em className="text-danger">*</em></label>
                            <input name="bank_additional"
                                type="text"
                                className="form-control"
                                value={`${user?.teacher_info?.bank_additional == null ? '' : user?.teacher_info?.bank_additional} `}
                            />
                        </div>
                        <div className="mb-2">
                            <label htmlFor="balance" className="form-label">Available Balance($)</label>
                            <input
                                type="text"
                                className="form-control"
                                step={'0.2'}
                                disabled
                                value={`${user?.balance}`}
                            />
                        </div>
                        <div className="mb-2">
                            <label htmlFor="amount" className="form-label">Withdrawal Amount($)</label>
                            <input
                                type="number"
                                min={0}
                                className="form-control"
                                id="amount"
                                required
                                name="amount"
                                step="any"
                                

                            />
                        </div>
                        {loading ? (
                            <span
                                className="spinner-border spinner-border-sm ml-5"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        ) : (
                            <button type="submit" className="btn btn-primary">Submit </button>
                        )}

                        
                    </form>
                </div>

            </div>
            <div className="col-12">
                <div className="table-responsive custom-table">
                    <table className="table table-nowrap mb-0">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Date</th>
                                <th>Payment Method</th>
                                <th>Amount</th>
                                <th>Fee</th>
                                <th>Total</th>
                                <th>Status</th>
                                <th>Account Info</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.results?.length>0 && data?.results?.map((item, index) => (
                               <>
                                <WithdrawRequestTr data={item} key={index} />
                               </>
                            ))}

                        </tbody>
                        <tfoot>
                            <tr >
                                <th></th>
                                <th></th>
                                <th>Total</th>
                                <th>{data?.results?.reduce((acc, item) => acc + item.amount, '$')}</th>
                                
                                <th>{data?.results?.reduce((acc, item) => acc + item.fees, '$')}</th>
                                <th>${data?.results?.reduce((acc, item) => acc + item.amount - item.fees, 0)}</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>




        </>
    )
}