export default function CourseContent(props){
    
    function convertMinutesToHours(minutes) {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
      
        // Format hours and minutes to be two digits
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(remainingMinutes).padStart(2, '0');
      
        return `${formattedHours}h ${formattedMinutes}m`;
      }

    return (
        <>
        <div className="courseDetailsTabHeading mt-3">
          <h2 className="fs-24-600 mb-0">Course content</h2>
          <div className="courseContentSubHeading">
            <span className="me-1">•</span>  <span className>{props?.data?.sections?.length} Sections</span>  <span className="me-1 ms-1">•</span>  <span>{props?.data?.lessons_count} Lectures</span>  
          </div>
        </div>
        <div className="courseDetailsLessons">
          <div className="courseDetailsLessonsArea">
            <div className="accordion" id="accordionExample">
            {props?.data?.sections?.length > 0 && props?.data?.sections?.map((section, i) => (
              <div className="accordion-item" key={i}>
                <h2 className="accordion-header">
                  <button className={`accordion-button course-accordion-btn  ${i==0?'':'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    {section.name}
                    <span className="lecturesHour"><span className="me-1">•</span>{section.lessons.length} Lectures</span>
                  </button>
                </h2>
                <div id="collapseOne" className={`accordion-collapse collapse ${i==0?'show':''}`} data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <ul className="list-unstyled">

                    {section.lessons?.length > 0 && section.lessons?.map((lesson,k) => (
                      <li className key={k}>
                        <div className="d-flex">
                          <div className="p-2 flex-grow-1">
                            <i className="fa-light fa-play-circle me-2" />
                            <a href="#" className={` ${lesson?.is_trial==1?'text-decoration-underline text-primary':'text-dark'}`}>{lesson.title}</a>
                          </div>

                          <div className={`p-2 ${lesson?.is_trial==1?'d-block':'d-none'}`}>
                            <a href="#" className={`${lesson?.is_trial==1?'text-decoration-underline text-primary':'text-dark'}`}>Preview</a>
                          </div>

                          <div className="p-2" dangerouslySetInnerHTML={{ __html: lesson.duration }} ></div>
                        </div>
                      </li>
                     ))}

                    </ul>
                  </div>
                </div>
              </div>
))}

       
              
            </div>
          </div>
        </div>
        </>
    )
}